@media (min-width: 900px) {
  body header {
    min-height: 350px;
    width: 100%;
  }
}
body navigation-bar {
  background-color: initial !important;
}
body navigation-bar::before {
  display: none;
}
body > gallery-container {
  z-index: -1;
  grid-row: 1/span 2;
  grid-column: 1/-1;
  --background-theme-one: #1E1A1A;
  --background-theme-two: var(--primary, #131f6b);
}
body > gallery-container .slide.image {
  filter: saturate(0.4);
}
body > gallery-container::after {
  content: "";
  position: absolute;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, var(--background-theme-one) 5%, rgba(19, 31, 107, 0.5), var(--background-theme-two) 80%);
}

.header {
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  place-self: center;
  max-width: 1400px;
  text-align: center;
  align-self: flex-start;
  position: relative;
  --translate-y: -20px;
}
@media (max-width: 900px) {
  .header {
    padding-bottom: 0;
  }
}
@media (min-width: 901px) {
  .header .heading,
  .header .sub-heading {
    max-width: 50%;
    margin: 0 auto;
  }
}
.header .heading {
  color: white;
}
.header .sub-heading {
  font-size: 18px;
}
.header .sub-heading,
.header tag-line {
  margin: var(--gutter1) auto;
}
.header tag-line {
  padding-bottom: 10px;
}
.header .heading,
.header .sub-heading,
.header search-bar {
  opacity: 0;
  transform: translateY(var(--translate-y));
}
.header.in-view .heading,
.header.in-view .sub-heading,
.header.in-view search-bar {
  --translate-y: 0;
  opacity: 1;
  transition: opacity 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s, transform 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
}
.header.in-view .sub-heading {
  transition-delay: 0.4s, 0.4s;
}
.header.in-view search-bar {
  transition-delay: 0.6s, 0.6s;
}
.header:has(.ecovadis-banner) {
  padding-bottom: 0;
}
.header:has(.ecovadis-banner) .ecovadis-banner {
  margin-top: var(--spacer1);
}

.iso-banner {
  grid-row: 3;
  width: 100vw;
  left: calc(-50vw + 50%);
  background-color: #76bb28;
  text-align: center;
  color: white;
  padding-top: calc(var(--gap) * 2);
  padding-bottom: calc(var(--gap) * 2);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.iso-banner h2 {
  margin-bottom: var(--gap);
  color: white;
}
.iso-banner h2 span {
  display: block;
  font-size: 16px;
  font-weight: 300;
  padding: 0 calc(var(--gap) * 2);
  font-family: "montserrat", sans-serif;
}
.iso-banner ~ main {
  --row-main: 4;
}
@media (min-width: 768px) {
  .iso-banner {
    position: absolute;
    height: 180px;
    width: auto;
    aspect-ratio: 1;
    border-radius: 50%;
    top: 20vh;
    right: 10vw;
    left: unset;
    transform: rotate(15deg);
    grid-row: unset;
    padding: var(--gap) calc(var(--gap) * 0.5);
  }
  .iso-banner h2 {
    margin-bottom: 0;
  }
  .iso-banner ~ main {
    --row-main: 3;
  }
}

.ecovadis-top-banner {
  display: none;
  position: absolute;
  height: 150px;
  width: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  top: 2em;
  left: 0;
}
.ecovadis-top-banner img {
  width: 100%;
}
@media (min-width: 901px) {
  .ecovadis-top-banner {
    display: block;
  }
}

navigation-bar {
  background-color: initial;
  background: initial;
  align-self: flex-start;
  padding-top: var(--spacer0);
  padding-bottom: var(--spacer0);
}
navigation-bar::before {
  display: none;
}

#hero.promos {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  box-shadow: initial;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 100%;
  grid-gap: 0;
  overflow-y: clip;
}
#hero.promos .promo tag-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 769px) {
  #hero.promos {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100%;
  }
}
#hero.promos > .image,
#hero.promos .simpleParallax {
  position: absolute;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  top: 0;
}
#hero.promos .quote {
  --quote-icon-size: 40px;
  position: relative;
  grid-row: 1;
  background-color: rgba(19, 31, 107, 0.9);
  border-radius: 0;
  border: none;
  height: fit-content;
  padding: calc(var(--spacer1) + var(--quote-icon-size)) var(--gutter4) var(--spacer1) var(--gutter4);
  display: flex;
  flex-direction: column;
}
#hero.promos .quote blockquote {
  color: white;
}
#hero.promos .quote blockquote::before, #hero.promos .quote blockquote::after {
  opacity: 0.2;
  width: var(--quote-icon-size);
  height: var(--quote-icon-size);
  top: calc(-1 * var(--quote-icon-size));
}
#hero.promos .quote blockquote::after {
  display: none;
}
#hero.promos .quote img {
  margin-top: var(--gap);
  max-width: 130px;
}
#hero.promos .quote figcaption {
  color: #DF3035;
  width: 100%;
  text-transform: uppercase;
}
#hero.promos .quote figcaption cite {
  font-style: normal;
  font-family: lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#hero.promos .quote figcaption cite svg {
  display: none;
}
#hero.promos .quote figcaption cite::before {
  content: initial;
}
@media (hover: hover) {
  #hero.promos .quote figcaption cite a:hover {
    color: white;
  }
}
#hero.promos .metal-links {
  grid-row: 1/-1;
  padding: var(--gutter2);
  display: grid;
  grid-gap: 0 var(--gutter1);
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
}
@media (max-width: 480px) {
  #hero.promos .metal-links {
    grid-template-columns: 1fr;
  }
}
#hero.promos .metal-links .text {
  grid-column: 1/-1;
}
#hero.promos .metal-links .text > h3 {
  font-size: 2em;
  line-height: 1.3;
  margin-bottom: calc(var(--gap) * 0.5);
  color: #131f6b;
}
#hero.promos .metal-links .text > h3 + p {
  color: #DF3035;
  max-width: 600px;
  font-size: 1.0625em;
  line-height: 1.6;
}
#hero.promos .metal-links p.contains-button {
  margin-top: var(--gutter1);
  color: #131f6b;
  display: block;
  font-size: 1.0625em;
  line-height: 1.6;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#hero.promos .metal-links p.contains-button .button {
  width: fit-content;
  white-space: nowrap;
}
#hero.promos .metal-links .list-dropdowns {
  margin-top: var(--gutter1);
}
#hero.promos .metal-links .list-dropdowns .heading {
  font-size: 1.5625em;
  line-height: 1.6;
  text-transform: uppercase;
  color: #3d3f7f;
}
#hero.promos .metal-links button-container {
  margin-top: var(--spacer0);
  grid-column: 1/-1;
}
@media (min-width: 1681px) {
  #hero.promos .quote {
    padding-left: calc(var(--computed-gutter) + var(--gutter6));
  }
}
@media (max-width: 1400px) {
  #hero.promos .quote {
    --quote-icon-size: 20px;
    padding-left: var(--gutter2);
    padding-right: var(--gutter2);
  }
}
@media (max-width: 1120px) {
  #hero.promos {
    grid-template-rows: auto;
    grid-gap: 0;
  }
  #hero.promos .quote {
    align-items: center;
    text-align: center;
    position: relative;
  }
  #hero.promos .quote blockquote {
    position: relative;
    max-width: 700px;
  }
  #hero.promos .quote blockquote::before, #hero.promos .quote blockquote::after {
    padding: 0;
    width: 20px;
    height: 20px;
    position: absolute;
  }
  #hero.promos .quote blockquote::before {
    left: calc(var(--gap) * 0.5);
  }
  #hero.promos .quote blockquote::after {
    right: calc(var(--gap) * 0.5);
    display: block;
  }
  #hero.promos .metal-links button-container {
    justify-content: center;
  }
}

markets-summary {
  margin-top: 1px;
  box-shadow: 0px -1px var(--border-top-colour, #46528a);
}
markets-summary::before {
  box-shadow: inherit;
  border: initial;
}