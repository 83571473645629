@use '_modules' as *;

body {
  header {
    @media (min-width: 900px) {
      min-height: 350px;
      width: 100%;
    }
  }

  navigation-bar {
    background-color: initial !important;
    &::before {
      display: none;
    }
  }

  > gallery-container {
    z-index: -1;
    grid-row: 1 / span 2;
    grid-column: 1 / -1;

    --background-theme-one: #{$black};
    --background-theme-two: #{$primary};

    .slide {
      &.image {
        filter: saturate(0.4);
      }
    }

    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, var(--background-theme-one) 5%, rgba(19, 31, 107, 0.5), var(--background-theme-two) 80%);
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  place-self: center;
  max-width: 1400px;
  text-align: center;
  align-self: flex-start;
  position: relative;

  @include break-in(900) {
    padding-bottom: 0;
  }

  @include break-out(900) {
    .heading,
    .sub-heading {
      max-width: 50%;
      margin: 0 auto;
    }
  }

  .heading {
    color: white;
  }

  .sub-heading {
    font-size: 18px;
  }

  .sub-heading,
  tag-line {
    margin: $gutter auto;
  }

  tag-line {
    padding-bottom: 10px;
  }

  // Transitions ---------------------------------------------------------------

  --translate-y: -20px;

  .heading,
  .sub-heading,
  search-bar {
    opacity: 0;
    transform: translateY(var(--translate-y));
  }

  &.in-view {
    .heading,
    .sub-heading,
    search-bar {
      --translate-y: 0;
      opacity: 1;
      transition: opacity 1.2s $ease-out-quart 0.2s, transform 1.2s $ease-out-quart 0.2s;
    }
    .sub-heading {
      transition-delay: 0.4s, 0.4s;
    }
    search-bar {
      transition-delay: 0.6s, 0.6s;
    }
  }

  &:has(.ecovadis-banner) {
    padding-bottom: 0;
    .ecovadis-banner {
      margin-top: $spacer;
    }
  }
}

.iso-banner {
  grid-row: 3;
  width: 100vw;
  left: calc(-50vw + 50%);
  background-color: $green2;
  text-align: center;
  color: white;
  padding-top: $gap2;
  padding-bottom: $gap2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    margin-bottom: $gap;
    color: white;
    span {
      display: block;
      font-size: 16px;
      font-weight: 300;
      padding: 0 $gap2;
      font-family: 'montserrat', sans-serif;
    }
  }

  & ~ main {
    --row-main: 4;
  }

  @media (min-width: 768px) {
    position: absolute;
    height: 180px;
    width: auto;
    aspect-ratio: 1;
    border-radius: 50%;
    top: 20vh;
    right: 10vw;
    left: unset;
    transform: rotate(15deg);
    grid-row: unset;
    padding: $gap $gap0;
    h2 {
      margin-bottom: 0;
    }

    & ~ main {
      --row-main: 3;
    }
  }
}

.ecovadis-top-banner {
  display: none;
  position: absolute;
  height: 150px;
  width: auto;
  aspect-ratio: 1;
  border-radius: 50%;
  top: 2em;
  left: 0;

  img {
    width: 100%;
  }

  @include break-out(900) {
    display: block;
  }
}

navigation-bar {
  background-color: initial;
  background: initial;
  align-self: flex-start;
  padding-top: $spacer0;
  padding-bottom: $spacer0;
  &::before {
    display: none;
  }
}

// Hero ------------------------------------------------------------------------

#hero.promos {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 100vw;
  margin-left: -50vw;
  left: 50%;
  box-shadow: initial;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 100%;
  grid-gap: 0;
  overflow-y: clip;
  .promo {
    tag-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  @include break-out(768) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 100%;
  }

  > .image,
  .simpleParallax {
    position: absolute;
    max-height: 100vh;
    height: 100%;
    width: 100%;
    top: 0;
  }
  .quote {
    --quote-icon-size: 40px;
    position: relative;
    grid-row: 1;
    background-color: rgba($blue, 0.9);
    border-radius: 0;
    border: none;
    height: fit-content;
    padding: calc(#{$spacer} + var(--quote-icon-size)) $gutter4 $spacer $gutter4;
    display: flex;
    flex-direction: column;

    blockquote {
      color: white;
      &::before,
      &::after {
        opacity: 0.2;
        width: var(--quote-icon-size);
        height: var(--quote-icon-size);
        top: calc(-1 * var(--quote-icon-size));
      }
      &::after {
        display: none;
      }
    }
    img {
      margin-top: $gap;
      max-width: 130px;
    }
    figcaption {
      color: $red;
      width: 100%;
      text-transform: uppercase;
      cite {
        font-style: normal;
        @include font-lato(bold);
        svg {
          display: none;
        }
        &::before {
          content: initial;
        }
        a {
          @include hover {
            color: white;
          }
        }
      }
    }
  }

  .metal-links {
    grid-row: 1 / -1;
    padding: $gutter2;
    display: grid;
    grid-gap: 0 $gutter;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));

    @include break-in(small) {
      grid-template-columns: 1fr;
    }

    .text {
      grid-column: 1 / -1;
      > h3 {
        @include heading-h2;
        margin-bottom: $gap0;
        color: $blue;
        + p {
          color: $red;
          max-width: 600px;
          @include heading-h4;
        }
      }
    }

    p.contains-button {
      margin-top: $gutter;
      color: $blue;
      display: block;
      @include heading-h4;
      display: flex;
      flex-wrap: wrap;
      // gap:$gap0 $gap;
      align-items: center;
      .button {
        width: fit-content;
        white-space: nowrap;
      }
    }
    .list-dropdowns {
      margin-top: $gutter;
      .heading {
        @include heading-h3;
        text-transform: uppercase;
        color: $blue3;
      }
    }

    button-container {
      margin-top: $spacer0;
      grid-column: 1 / -1;
    }
  }
  @include break-out(max) {
    .quote {
      padding-left: calc(var(--computed-gutter) + #{$gutter6});
    }
  }
  @include break-in(large) {
    .quote {
      --quote-icon-size: 20px;
      padding-left: $gutter2;
      padding-right: $gutter2;
    }
  }
  @include break-in(medium) {
    grid-template-rows: auto;
    grid-gap: 0;
    .quote {
      align-items: center;
      text-align: center;
      position: relative;

      blockquote {
        position: relative;
        max-width: 700px;
        &::before,
        &::after {
          padding: 0;
          width: 20px;
          height: 20px;
          position: absolute;
        }
        &::before {
          left: $gap0;
        }
        &::after {
          right: $gap0;
          display: block;
        }
      }
    }
    .metal-links button-container {
      justify-content: center;
    }
  }
}

markets-summary {
  margin-top: 1px;
  box-shadow: 0px -1px var(--border-top-colour, #46528a);
  &::before {
    box-shadow: inherit;
    border: initial;
  }
}
